import { ClubManagementStackParamList } from '@app/navigation/CoursesStack'
import { useAxios } from '@common/hooks/useAxios'
import type { StackScreenProps } from '@react-navigation/stack'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import ContentCard from '@app/components/atoms/ContentCard'
import ScreenWrapper from '@app/components/atoms/ScreenWrapper'
import RoundedButton from '@app/ui/rounded-button/RoundedButton'
import { useApi } from '@common/hooks/useApi'
import Spacer from 'react-styled-spacer'
import {
  GeneratedForms,
  RatingRevision,
  RatingTemplate,
} from '../../types/Templates'

import ContentHeading from '@app/components/atoms/ContentHeading'
import ContentSubHeading from '@app/components/atoms/ContentSubHeading'
import GoBackButton from '@app/components/atoms/GoBackButton'
import { CourseOptionView } from '@app/components/molecules/CourseInfo/styled'
import { EvalStatus } from '@app/courses/domain/ratingStatus'
import {
  deleteEvaluation,
  startEvaluation,
} from '@app/evaluation-core/evaluation'
import { goBack } from '@app/navigation'
import theme from '@app/ui/theme'
import {
  MiProjectBranch,
  ProjectCourse,
  ProjectHole,
} from '../../types/Project'
import RTEvaluationsList from '@app/components/molecules/EvaluationsList/RTEvaluationsList'
import { useRealTimeEvals } from '@common/hooks/useRealTimeEvals'
import ContentClubHeader from '@app/components/atoms/ContentClubHeader'
import TwoButtonModal from '@app/components/organisms/TwoButtonModal'
import { InLineSpinner } from '@app/evaluation/components/Spinner'
import InProgressEvaluationsList from '@app/components/molecules/EvaluationsList/InProgressEvaluationsList'
import FinalizedEvaluationsList from '@app/components/molecules/EvaluationsList/FinalizedEvaluationsList'

export interface EvaluationDoc {
  branchId: number
  clubId: number
  holeIds: string[]
  saveName?: string
  id: string
  status: EvalStatus
  users: {
    role: number
    userId: string
    email: string
  }[]
  version: string
  createdAt: Date
  manualVersion?: string
  updatedAt: Date
  savedAt: string | null
  finalizedAt: string | null
  ratingRevision: RatingRevision
}

export interface EvaluationDocWithForms extends EvaluationDoc {
  finalizedForms: GeneratedForms[] | undefined
}

type OwnProps = {}

type Props = OwnProps &
  StackScreenProps<ClubManagementStackParamList, 'CreateRating'>

function findCourseIds({
  clubHoles,
  holeIds,
}: {
  clubHoles: ProjectHole[]
  holeIds: string[]
}): string[] {
  const uniqueTgfCourseIds = new Set<string>()

  clubHoles.forEach((hole) => {
    const physicalID = hole.physicalID
    if (holeIds.includes(physicalID)) {
      uniqueTgfCourseIds.add(hole.tgfCourseID)
    }
  })

  return [...uniqueTgfCourseIds]
}

// This was copied from AdminPage, we should put this in a shared file instead // Stoffe
const convertStatusToText = (status: 0 | 1 | 2 | 3 | 4) => {
  switch (true) {
    case status === EvalStatus.IN_PROGRESS:
      return 'In Progress'
    case status === EvalStatus.FINALIZED:
      return 'Finalized'
    default:
      return 'Unknown'
  }
}
const CreateRatingPage: FunctionComponent<Props> = ({ route, navigation }) => {
  const axios = useAxios('/ratings/templates')
  const evalAxios = useAxios('/evaluations/import')
  const [coursesWithHoles, setCoursesWithHoles] = React.useState<
    {
      course: ProjectCourse
      source: number
      evaluations: EvaluationDoc[]
    }[]
  >()

  const { holeIds, templateId, club, branchId, isRateable } =
    route?.params ?? {}

  const { data, isLoading: branchLoading } = useApi<MiProjectBranch>(
    `/courses/projects/${club.id}`
  )

  const { data: rateableEvaluations, isLoading } = useApi<EvaluationDoc[]>(
    `/evaluations/import?holeId=${holeIds}&clubId=${club.id}`,
    undefined,
    {
      refreshInterval: 5000,
    }
  )

  const { data: template, isLoading: isTemplateLoading } =
    useApi<RatingTemplate>(
      `/ratings/templates?templateId=${templateId}`,
      undefined,
      {
        refreshInterval: 3000,
      }
    )

  const [templateName, setTemplateName] = React.useState<string>('')

  const { realTimeEvals, v1Evals, loadV1Evals, isLoadingV1 } =
    useRealTimeEvals(rateableEvaluations)
  /*
  useEffect(() => {
    const fetchRateableEvaluationsFromRealTime = () => {
      if (rateableEvaluations) {
        console.log('YEPPP')
        setRealTimeEvals(undefined)
        return rateableEvaluations.map(async (e) => {
          return new Promise<void>((resolve) =>
            setImmediate(async () => {
              const rte = await getRealTimeEvaluation(e.id)

              if (rte) {
                //console.log(`fetched ${rte?.clubId}`)
                setRealTimeEvals((prevEvals) => ({
                  ...prevEvals,
                  [e.id]: rte,
                }))
              } else console.log(`Unable to find RT Evaluation for ${e.id}`)
            })
          )
        })
      }
    }

    fetchRateableEvaluationsFromRealTime()

    if (rateableEvaluations)
      setFBPaths(rateableEvaluations?.map((x) => `/evaluations/${x.id}`))
  }, [rateableEvaluations])
*/
  // TODO v1 evals

  const clubHoles = data?.courses.flatMap((course) => course.holes) ?? []
  const ratingCourses = findCourseIds({ clubHoles, holeIds })
  const courses = data?.courses.filter((course) =>
    ratingCourses.includes(course.id)
  )

  const maybeStartNewEvaluationFn = async (
    clubId: number,
    branchId: string,
    holeIds: string[]
  ) => {
    if (inProgressEvaluations.length > 0) setShowStartNewRatingModal(true)
    else startEvaluation(+clubId, branchId, holeIds)
  }

  const startNewEvaluationFn = async (
    clubId: number,
    branchId: string,
    holeIds: string[]
  ) => {
    startEvaluation(clubId, branchId, holeIds)
  }

  const importEvaluation1Fn = async (
    clubId: number,
    branchId: string,
    holeIds: string[],
    evaluationId?: string | null
  ) => {
    await startEvaluation(
      clubId,
      branchId,
      holeIds,
      undefined,
      undefined,
      evaluationId
    )
  }
  const [showStartNewRatingModal, setShowStartNewRatingModal] = useState(false)

  useEffect(() => {
    const courseHoleIds = courses?.map((course, index) => ({
      course,
      source: index + 1,
    }))

    const getRateableEvaluations = async (holeIds: string[]) => {
      try {
        const { data } = await evalAxios.get<EvaluationDoc[]>(
          `?holeId=${holeIds}&clubId=${club.id}`
        )
        return data
      } catch (error) {
        console.error('Error fetching evaluations:', error)
        return []
      }
    }

    const fetchAllEvaluations = async () => {
      if (!courseHoleIds) {
        return []
      }

      try {
        const evaluationsPromises = courseHoleIds.map((course) =>
          getRateableEvaluations(
            course.course.holes.map((hole) => hole.physicalID)
          ).then((evaluations) => ({
            ...course,
            evaluations: evaluations.filter(
              (evalu) => evalu.status === EvalStatus.FINALIZED
            ),
          }))
        )

        const evaluations = await Promise.all(evaluationsPromises)

        if (evaluations && evaluations.length > 0) {
          setCoursesWithHoles(evaluations)
        }
      } catch (error) {
        console.error('Error in fetchAllEvaluations:', error)
      }
    }

    fetchAllEvaluations()
  }, [data?.courses, club.id])

  const getFinalizedEvaluation = (evaluationId: string) => {
    return template?.finalizedForms?.filter(
      (evalu) => evalu.evaluationId === evaluationId
    )
  }
  const getFinalizedForms = useCallback(
    (evaluationId: string) => {
      return getFinalizedEvaluation(evaluationId)
    },
    [template]
  )

  const docTitle = useMemo(() => {
    if (!!templateName && templateName !== template?.name) {
      return `${club?.name} - ${templateName}`
    }
    return `${club?.name} - ${template?.name}`
  }, [template, templateName])

  useEffect(() => {
    if (template?.name) {
      setTemplateName(template.name)
    }
  }, [template])

  const inProgressSortFn = (a: EvaluationDoc, b: EvaluationDoc) => {
    const aDate = a?.ratingRevision?.ratingDate
    const bDate = b?.ratingRevision?.ratingDate
    if (!aDate) return 1
    if (!bDate) return -1
    return new Date(bDate).getTime() - new Date(aDate).getTime()
  }

  const savedEvaluationsSortFn = (
    a: EvaluationDocWithForms,
    b: EvaluationDocWithForms
  ) => {
    const aDate = a?.ratingRevision?.ratingDate
    const bDate = b?.ratingRevision?.ratingDate
    if (!aDate) return 1
    if (!bDate) return -1
    return new Date(bDate).getTime() - new Date(aDate).getTime()
  }

  const [savedEvaluations, setSavedEvaluations] =
    useState<EvaluationDocWithForms[]>()
  const [inProgressEvaluations, setInProgressEvaluations] = useState<
    EvaluationDoc[]
  >([])

  useMemo(() => {
    if (!template) return

    const {
      savedEvaluations: _savedEvaluations,
      inProgressEvaluations: _inProgressEvaluations,
    } = (rateableEvaluations || []).reduce(
      (result, evaluation) => {
        if (evaluation.status === EvalStatus.FINALIZED) {
          const finalizedForms = getFinalizedForms(evaluation.id)
          result.savedEvaluations.push({ ...evaluation, finalizedForms })
        } else {
          result.inProgressEvaluations.push(evaluation)
        }
        return result
      },
      { savedEvaluations: [], inProgressEvaluations: [] } as {
        savedEvaluations: EvaluationDocWithForms[]
        inProgressEvaluations: EvaluationDoc[]
      }
    )

    _inProgressEvaluations.sort(inProgressSortFn)
    _savedEvaluations.sort(savedEvaluationsSortFn)

    setInProgressEvaluations(_inProgressEvaluations)
    setSavedEvaluations(_savedEvaluations)
  }, [rateableEvaluations, template])

  if (isLoading || isTemplateLoading) {
    return <div>Loading...</div>
  }

  if (template?.name && club.name) {
    document.title = docTitle
  }

  const updateRatingTemplate = async () => {
    await axios.patch('/', {
      name: templateName.trim(),
      id: templateId,
    })
  }

  return (
    <ScreenWrapper>
      <Spacer h={24} />
      <GoBackButton
        text="Go back"
        onPress={goBack}
        portraitMode={screen.width < screen.height}
        style={{ marginLeft: 24 }}
      />
      <ContentCard>
        <ContentClubHeader
          title={docTitle}
          optionView={<CourseOptionView />}
          club={club}
        />
        {/* <CourseOptionView> */}
        {/*   <div> */}
        {/*     <div */}
        {/*       style={{ */}
        {/*         display: 'flex', */}
        {/*         flexDirection: 'column', */}
        {/*         justifyContent: 'space-around', */}
        {/*       }} */}
        {/*     > */}
        {/*       <span style={{ lineHeight: '15px' }}>Course Name</span> */}
        {/*       <Spacer h={4} /> */}
        {/*       <span style={{ fontSize: 10, lineHeight: '10px' }}> */}
        {/*         This will be the name that appears on all forms. */}
        {/*       </span> */}
        {/*     </div> */}
        {/*     <input */}
        {/*       style={{ width: 400 }} */}
        {/*       placeholder="Course name" */}
        {/*       defaultValue={templateName || template?.name} */}
        {/*       onChange={(e) => setTemplateName(e.target.value)} */}
        {/*       onBlur={updateRatingTemplate} */}
        {/*       className="templates-course-name" */}
        {/*     /> */}
        {/*   </div> */}
        {/* </CourseOptionView> */}
      </ContentCard>
      <ContentCard>
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <ContentHeading>In Progress Ratings</ContentHeading>
          <RoundedButton
            isDefault={inProgressEvaluations?.length <= 0}
            style={{
              width: 300,
            }}
            title="Start New Rating"
            disabled={!isRateable && isRateable !== undefined}
            onPress={() =>
              maybeStartNewEvaluationFn(+club.id, branchId, holeIds)
            }
          />
        </div>
        {!isRateable && isRateable !== undefined && (
          <ContentSubHeading
            style={{ color: theme.colors.warning, opacity: 0.7 }}
          >
            This course is missing information, please upload the latest
            information via LiveCR.
          </ContentSubHeading>
        )}
        <Spacer h={24} />
        <InProgressEvaluationsList
          club={club}
          evaluations={inProgressEvaluations}
          realTimeEvals={realTimeEvals}
          loading={isLoading}
          templateId={templateId}
          deleteAction={async (evaluationId) =>
            await deleteEvaluation(evaluationId)
          }
          noDataText="No ratings for this course are currently in progress."
        />
      </ContentCard>
      <ContentCard>
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <ContentHeading>Finalized Ratings</ContentHeading>
        </div>
        <Spacer h={24} />
        <FinalizedEvaluationsList
          club={club}
          evaluations={savedEvaluations}
          loading={isLoading}
          templateId={templateId}
          deleteAction={async (evaluationId) =>
            await deleteEvaluation(evaluationId)
          }
          noDataText="No ratings for this course are finalized."
        />
      </ContentCard>
      <ContentCard>
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <ContentHeading>V1 Ratings</ContentHeading>'
          {/*<RoundedButton
            disabled={
              branchLoading || (data?.courses && data?.courses?.length <= 0)
            }
            title="Load V1 Ratings"
            onPress={() => {
              return
            }}
          />
          */}
          <RoundedButton
            onPress={() => loadV1Evals()}
            title="Load V1 evaluations"
          />
        </div>
        <Spacer h={4} />
        <span style={{ lineHeight: '15px' }}>
          Here you can see previous evaluations from old WHS V1 and import them.
        </span>
        <Spacer h={24} />
        <table style={{ width: '100%' }}>
          <thead>
            <tr>
              <th style={{ textAlign: 'start' }}>Key</th>
              <th style={{ textAlign: 'start' }}>Club Name</th>
              <th style={{ textAlign: 'start' }}>Course Name</th>
              <th style={{ textAlign: 'start' }}>Rating Date</th>
              <th style={{ textAlign: 'start' }}>Status</th>
              <th style={{ textAlign: 'start' }}></th>
            </tr>
          </thead>
          <tbody>
            {isLoadingV1 && (
              <tr key={`isLoadingV1`}>
                <td colSpan={6}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <InLineSpinner size={32} />
                  </div>
                </td>
              </tr>
            )}
            {!isLoadingV1 &&
              v1Evals &&
              Array.from(v1Evals)
                .filter(([evalId, evaluation]) => {
                  return (
                    (!evaluation.courseId &&
                      Number(club.id) == evaluation.clubId) ||
                    club?.courses?.some(
                      (course) =>
                        Number(course.id) === Number(evaluation.courseId) &&
                        evaluation.courseName === templateName
                    )
                  )
                })
                .map(([evalId, evaluation]) => (
                  <tr key={evalId}>
                    <td>{evalId}</td>
                    <td>{evaluation.clubName}</td>
                    <td>{evaluation.courseName}</td>
                    <td>{evaluation.evaluationDate}</td>
                    <td>{convertStatusToText(evaluation.status as any)}</td>
                    <td>
                      <RoundedButton
                        title="Import"
                        onPress={async () =>
                          await importEvaluation1Fn(
                            +club.id,
                            club.branchID,
                            holeIds,
                            evalId
                          )
                        }
                      />
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
      </ContentCard>
      {false && (
        <ContentCard>
          <div
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <ContentHeading>Club ratings</ContentHeading>
          </div>
          <Spacer h={24} />
          <RTEvaluationsList
            club={club}
            realTimeEvals={realTimeEvals}
            evaluations={savedEvaluations}
            loading={isLoading}
            templateId={templateId}
            deleteAction={async (evaluationId) =>
              await deleteEvaluation(evaluationId)
            }
            noDataText={`No ratings found by club id.`}
          />
        </ContentCard>
      )}

      <TwoButtonModal
        isVisible={showStartNewRatingModal}
        title="Start new rating"
        description="There is currently raings in progress, are you sure you want to start a new rating?"
        onPressRight={async () =>
          await startNewEvaluationFn(+club.id, branchId, holeIds)
        }
        onPressLeft={() => setShowStartNewRatingModal(false)}
        rightButtonText="Start new rating"
        leftButtonText="Cancel"
        destructive
      />
    </ScreenWrapper>
  )
}

export default CreateRatingPage
