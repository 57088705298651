import {
  AvatarContainer,
  ConnectionButton,
  ConnectionText,
  ContentWrapper,
  EvaluationCard,
  HoleIsLockedContainer,
  TopBarContainer,
  Wrapper,
} from './Evaluation.styles'
import { GolferShortName, LockStatus, MITee } from '@app/evaluation-core/types'
import React, { useEffect, useRef, useState } from 'react'
import { resetHole, setHoleStatus } from '@app/evaluation-core'
import FeatherIcon from '@ovaeasy/react-native-vector-icons/Feather'

import { AdjustmentGroups } from '@app/evaluation-adjustments'
import Avatar from '@app/components/atoms/Avatar'
import { DocumentHelmet } from '@app/helmet/Helmet'
import { EvaluationBoundary } from '../../boundary'
import EvaluationSideBar from '@app/components/molecules/EvaluationSideBar'
import FilterTees from '@app/components/molecules/FilterTees'
import LiveUpdatesModal from '@app/components/organisms/LiveUpdatesModal'
import ModalOverlay from '@app/evaluation-modal'
import { ResetHoleConfirmationModal } from './ResetHoleConfirmationModal'
import { TeeGrid } from './TeeItem.styles'
import { TeeItem } from './TeeItem'
import { ActivityIndicator, View } from 'react-native'
import { resetModifications } from '@app/evaluation-core/modifications'
import { setSelectedHole } from '@app/evaluation-core/actions/evaluationActions'
import theme from '@app/ui/theme'
import { useDispatch } from 'react-redux'
import { useEvaluation } from '@common/hooks'
import useEvaluationLockedState from '@common/hooks/useEvaluationLockedState'
import useEvaluationUser from '@common/hooks/useEvaluationUser'
import useFilterTees from '@common/hooks/useFilterTees'
import { useIsFocused } from '@react-navigation/native'
import { useMediaQuery } from 'react-responsive'
import useNotification from '@common/hooks/useNotification'
import useUser from '@common/hooks/useUser'
import useEvaluatable from './useEvaluatable'
import { useRTDBConnection } from '@common/hooks/databaseConnection'
import { CopiedTeeProvider } from './CopiedTeeContext'
import Spacer from 'react-styled-spacer'
import InfoDropdown from '@app/components/molecules/InfoDropdown'
import { EvaluationActionMenu } from './EvaluationActionMenu'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export interface EvaluationProps {}

const Evaluation: React.FC<EvaluationProps> = () => {
  const { isConnected, connect, loading } = useRTDBConnection()
  const holeLocked = useEvaluationLockedState()
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1524px)' })
  const [collapsedMode, setCollapsedMode] = useState(false)
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [prepSubmitted, setPredSubmitted] = useState(false)

  const [sideBarHeight, setSideBarHeight] = useState(0)

  const sideBarRef = useRef<HTMLDivElement>(null)
  const closeModal = () => setShowCancelModal(false)
  const openModal = () => setShowCancelModal(true)
  const dropMenuRef = useRef<HTMLDivElement>(
    null
  ) as React.MutableRefObject<HTMLDivElement>
  const isFocused = useIsFocused()
  const dispatch = useDispatch<any>()
  const userId = useUser()?.id
  const [shouldShowNotifications, holeNotifications, holeObj] =
    useNotification()

  const { currentId, evaluation } = useEvaluation()
  const { view } = useEvaluationUser()

  const [
    filteredTees,
    filterStatusValue,
    setFilterStatusValue,
    womenMenStatusValue,
    setWomenMenStatusValue,
  ] = useFilterTees()

  useEffect(() => {
    setSideBarHeight(sideBarRef.current?.clientHeight || 0)
  }, [sideBarRef.current?.clientHeight])

  useEffect(() => {
    setCollapsedMode(isTabletOrMobile)
  }, [isTabletOrMobile])

  if (!evaluation.holes) {
    return null
  } else {
    if (isFocused && !prepSubmitted) {
      setPredSubmitted(true)
    }
  }

  const flippedLockStatus =
    holeLocked === LockStatus.UNLOCKED ? LockStatus.LOCKED : LockStatus.UNLOCKED

  const setHoleLocked = () =>
    setHoleStatus(currentId as string, flippedLockStatus, view?.hole)

  const lockAllHoles = () => {
    const allHolesIsLocked =
      evaluation?.holes.length > 0 &&
      evaluation?.holes?.filter((x) => x.locked).length ==
        evaluation?.holes.length

    evaluation.holes.forEach((hole, index) => {
      setHoleStatus(
        currentId as string,
        !allHolesIsLocked ? LockStatus.LOCKED : LockStatus.UNLOCKED,
        index
      )
    })
  }

  if (!currentId) return null

  if (!evaluation || !userId || !evaluation) return null

  const resetHoleFn = () => {
    resetModifications({ evaluationId: currentId, hole: view?.hole })
    resetHole(view?.hole, currentId)
    closeModal()
  }

  const setModalState = (
    hole: number | undefined,
    tee: number | undefined,
    golfer: GolferShortName,
    adjustment: number | undefined
  ) => dispatch(setSelectedHole(hole as number, tee, golfer, adjustment))

  const holeIncrementState = () => {
    if (view?.hole + 1 < evaluation.holes?.length) {
      setModalState(view?.hole + 1, view.tee, view?.golfer, view?.adjustment)
    }
  }
  const holeDecrementState = () => {
    if (view?.hole > 0)
      setModalState(view?.hole - 1, view?.tee, view?.golfer, view?.adjustment)
  }
  const renderAdjustmentView = () => {
    return (
      <ModalOverlay
        open={view && view?.adjustment !== -1}
        setModalState={setModalState}
        height={sideBarHeight ? sideBarHeight : '100%'}
      >
        <AdjustmentGroups
          selectedGroup={view?.adjustment ?? -1}
          evaluationState={{ evaluation, currentId }}
        />
      </ModalOverlay>
    )
  }

  const renderTeeGridView = () => {
    return (
      <CopiedTeeProvider>
        <TeeGrid collapsed={collapsedMode}>
          {filteredTees &&
            filteredTees.map((tee, index: number) => (
              <TeeItem key={index} tee={tee} index={index} />
            ))}
          {!filteredTees &&
            Array.from({ length: 5 }, (_, index) => (
              <View
                key={index}
                style={{ flexDirection: 'column', marginTop: 16 }}
              >
                <Skeleton width={270} height={40} />
                <Skeleton width={270} height={40} />
                <Skeleton
                  width={270}
                  height={600}
                  style={{ marginTop: 5, marginRight: 10 }}
                />
              </View>
            ))}
        </TeeGrid>
      </CopiedTeeProvider>
    )
  }

  const holeIsLocked = holeLocked === LockStatus.LOCKED
  const readOnly = holeLocked === LockStatus.READ_ONLY

  const holeLockedText = `Hole: ${
    view?.hole + 1
  } has been locked by your team leader.`

  const readOnlyText = `This rating is archived and in read-only mode.`

  return (
    <EvaluationBoundary>
      <Wrapper>
        <ContentWrapper>
          <TopBarContainer>
            <EvaluationActionMenu userId={String(userId)} />
            <Spacer w={8} />
            <InfoDropdown evaluation={evaluation} relative={true} yAdj={40} />
            {view?.adjustment === -1 && <Spacer w={8} />}
            {view?.adjustment === -1 && (
              <FilterTees
                evaluation={evaluation}
                view={view}
                filterStatusValue={filterStatusValue}
                setFilterStatusValue={setFilterStatusValue}
                setWomenMenStatusValue={setWomenMenStatusValue}
                womenMenStatusValue={womenMenStatusValue}
              />
            )}

            <HoleIsLockedContainer
              style={{
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <span
                style={{
                  color: theme.colors.primary,
                  fontWeight: 'bold',
                  whiteSpace: 'pre-wrap',
                  textAlign: 'center',
                }}
              >
                {holeIsLocked && holeLockedText}
                {readOnly && readOnlyText}
              </span>
            </HoleIsLockedContainer>

            <View style={{ marginRight: 20 }}>
              <AvatarContainer
                padding={8}
                width={Object.values(evaluation.users)?.length * 30}
              >
                {Object.values(evaluation.users).map((user, i) => (
                  <Avatar
                    key={user?.userId}
                    userColor={user.view?.color}
                    isLastIndex={
                      i + 1 === Object.values(evaluation.users)?.length
                    }
                    zIndex={i}
                    userId={user.view?.email ?? ''}
                  />
                ))}
              </AvatarContainer>
            </View>
          </TopBarContainer>

          <DocumentHelmet title="Evaluation" description="" />
          {shouldShowNotifications && (
            <LiveUpdatesModal
              collapsedMode={collapsedMode}
              holeArray={holeNotifications}
              title={`LiveCR Updates - Hole: ${view?.hole + 1}`}
              description="Please accept or reject these all to proceed."
              isVisible={shouldShowNotifications}
              holeObj={holeObj}
              setLoading={console.log}
            />
          )}
          <EvaluationCard
            modalShowing={shouldShowNotifications}
            inAdjustment={view?.adjustment !== -1}
          >
            <EvaluationSideBar
              collapsedMode={collapsedMode}
              dropMenuRef={dropMenuRef}
              setCollapsedMode={setCollapsedMode}
              holeDecrementState={holeDecrementState}
              holeIncrementState={holeIncrementState}
              openModal={openModal}
              setHoleLocked={setHoleLocked}
              lockAllHoles={lockAllHoles}
            />

            {view?.adjustment !== -1
              ? renderAdjustmentView()
              : renderTeeGridView()}
          </EvaluationCard>
          <ResetHoleConfirmationModal
            hole={view?.hole + 1}
            closeModal={closeModal}
            showModal={showCancelModal}
            acceptFunction={resetHoleFn}
          />
        </ContentWrapper>
        {!isConnected && (
          <ConnectionButton onClick={connect}>
            {loading ? (
              <ActivityIndicator size={40} color={theme.colors.white} />
            ) : (
              <FeatherIcon
                name="cloud-off"
                size={40}
                color={theme.colors.white}
              />
            )}
            <ConnectionText>
              You have lost connection to the server.{'\n'}Please press this
              window to reconenct.
            </ConnectionText>
          </ConnectionButton>
        )}
      </Wrapper>
    </EvaluationBoundary>
  )
}

export default Evaluation
